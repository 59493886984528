import { FC, useState } from 'react'

import { useApolloClient } from '@apollo/client'
import { captureEvent } from '@sentry/browser'
import PromisePool from '@supercharge/promise-pool'
import type {
  CreateSentimentAnalysisPermissionMutation,
  CreateSentimentAnalysisPermissionMutationVariables,
  UpdateSentimentAnalysisPermissionMutation,
  UpdateSentimentAnalysisPermissionMutationVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import type { Membership } from '../SettingsSentimentAnalysisCell'
import {
  CREATE_SA_PERMISSION,
  UPDATE_SA_PERMISSION,
} from '../SettingsSentimentAnalysisQueries'

import MemberManagementGrid from './MemberManagementGrid'
import HeaderBar from './MemberManagementHeaderBar'

interface Props {
  memberships: Membership[]
}

const SentimentAnalysisMemberManagement: FC<Props> = ({ memberships }) => {
  const [searchValue, setSearchValue] = useState('')
  const [isInvitingUsers, setIsInvitingUsers] = useState(false)
  const [selectedRows, setSelectedRows] = useState<Membership[]>([])
  const apolloClient = useApolloClient()

  const [createSAPermission] = useMutation<
    CreateSentimentAnalysisPermissionMutation,
    CreateSentimentAnalysisPermissionMutationVariables
  >(CREATE_SA_PERMISSION)

  const inviteUsers = async () => {
    setIsInvitingUsers(true)
    let numberOfErrors = 0

    const memberships = selectedRows.map((membership) => {
      return {
        membershipId: Number(membership.id),
        disabled: false,
        invited: true,
      }
    })

    await PromisePool.withConcurrency(5)
      .for(memberships)
      .handleError((error, membership, pool) => {
        numberOfErrors++
        if (numberOfErrors > 5) {
          captureEvent({
            message:
              'createSAPermission: Too many errors inviting users, stopping processing',
            extra: { error },
          })
          return pool.stop()
        }
        captureEvent({
          message: 'createSAPermission: Error inviting user',
          extra: { error, membership },
        })
      })
      .onTaskStarted(() => {
        setIsInvitingUsers(true)
      })
      .onTaskFinished(() => {
        apolloClient.refetchQueries({
          include: ['FindSettingsSentimentAnalysisQuery'],
        })
        setIsInvitingUsers(false)
      })
      .process(async (membership) => {
        const result = await createSAPermission({
          variables: {
            sendInvite: true,
            input: {
              membershipId: Number(membership.membershipId),
              disabled: false,
              invited: true,
            },
          },
        })
        return result
      })

    toast.success('Invitations processed')
  }

  const [updateSAPermission] = useMutation<
    UpdateSentimentAnalysisPermissionMutation,
    UpdateSentimentAnalysisPermissionMutationVariables
  >(UPDATE_SA_PERMISSION, {
    refetchQueries: ['FindSettingsSentimentAnalysisQuery'],
    awaitRefetchQueries: true,
  })

  const toggleUserAccess = async (recordId: number, toggledValue: boolean) => {
    await toast.promise(
      updateSAPermission({
        variables: {
          id: recordId,
          input: {
            disabled: toggledValue,
          },
        },
      }),
      {
        loading: 'Updating user access',
        success: 'User access updated successfully',
        error: (error) => `Failed to update user access: ${error.message}`,
      },
    )
  }

  const defaultNotificationRecipientMembers = memberships.filter(
    (membership) => membership.isSentimentNotificationDefaultRecipient,
  )

  return (
    <div className="flex flex-col gap-4 py-4">
      <HeaderBar
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        memberships={memberships}
        isInvitingUsers={isInvitingUsers}
        selectedRows={selectedRows}
        inviteUsers={inviteUsers}
        defaultNotificationRecipientMembers={
          defaultNotificationRecipientMembers
        }
      />
      <MemberManagementGrid
        memberships={memberships}
        setSelectedRows={setSelectedRows}
        searchValue={searchValue}
        toggleUserAccess={toggleUserAccess}
        isInvitingUsers={isInvitingUsers}
      />
    </div>
  )
}

export default SentimentAnalysisMemberManagement
