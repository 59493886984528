import { FC, useMemo } from 'react'

import { default as Empty } from 'src/components/Library/Empty'
import {
  SentimentAnalysisScoreGridProps,
  type SentimentAccordionMembership,
} from 'src/components/SentimentAnalysis/SentimentAnalysisHelper'
import SentimentAnalysisLandlordAccordion from 'src/components/SentimentAnalysis/SentimentAnalysisList/SentimentAnalysisLandlordAccordion'

import type { FindSettingsSentimentAnalysisMembership } from './SettingsSentimentAnalysisScoreGridCell'

const searchLandlordsInMembership = (
  membership: FindSettingsSentimentAnalysisMembership,
  searchText: string,
) => {
  if (membership.memberName?.toLowerCase().includes(searchText)) {
    return membership
  }

  return null
}

const SentimentAnalysisScoreGrid: FC<SentimentAnalysisScoreGridProps> = ({
  memberships,
  filterByMember,
  filterDateRange,
  filterESIRating,
  filterByFlag,
  filterByClientStatus,
  debouncedSearchText,
}) => {
  const filteredActiveMemberships = useMemo(() => {
    if (debouncedSearchText.length === 0 && filterByMember.length === 0)
      return memberships
    const searchText = debouncedSearchText.toLowerCase()

    // Extract group IDs from filterByMember
    const groupIds = filterByMember
      .filter((filter) => filter.type === 'GROUP')
      .flatMap((group) => [group.id, ...(group.childGroupIds || [])])

    // Extract member IDs from filterByMember
    const memberIds = filterByMember
      .filter((filter) => filter.type === 'MEMBER')
      .map((member) => member.id)

    return memberships
      .filter((membership) => {
        // Filter by member if filterByMember is not empty
        if (filterByMember.length > 0) {
          // Check if membership matches a member ID
          const isMemberMatch = memberIds.includes(membership.membershipId)

          // Check if membership belongs to a group
          const isGroupMatch = membership.membershipGroups?.some((group) =>
            groupIds.includes(group?.id),
          )

          return isMemberMatch || isGroupMatch
        }

        return true
      })
      .map((membership) => searchLandlordsInMembership(membership, searchText))
      .filter(Boolean)
  }, [memberships, debouncedSearchText, filterByMember])

  return (
    <div className="relative flex flex-col gap-2">
      {debouncedSearchText.length === 0 && memberships?.length === 0 && (
        <Empty title="No sentiment results found" className="mt-10" />
      )}
      {debouncedSearchText.length > 0 &&
        filteredActiveMemberships?.length === 0 && (
          <Empty title="No search results found" className="mt-10" />
        )}
      {filteredActiveMemberships?.length > 0 &&
        filteredActiveMemberships?.map((member) => {
          return (
            <SentimentAnalysisLandlordAccordion
              key={member.membershipId}
              // HACK: Coercing this type as the types weren't fully passing through - Fix later
              membership={member as SentimentAccordionMembership}
              filterDateRange={filterDateRange}
              filterESIRating={filterESIRating}
              filterByFlag={filterByFlag}
              filterByClientStatus={filterByClientStatus}
              debouncedSearchText={debouncedSearchText}
            />
          )
        })}
    </div>
  )
}

export default SentimentAnalysisScoreGrid
