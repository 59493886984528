import React, {
  useState,
  type Dispatch,
  type FC,
  type SetStateAction,
} from 'react'

import {
  InformationCircleIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline'
import { XMarkIcon } from '@heroicons/react/24/solid'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import {
  Autocomplete,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from '@mui/material'
import {
  UpdateDefaultNotificationRecipients,
  type UpdateDefaultNotificationRecipientsVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Button from 'src/components/Library/Button'
import type { FilterByOption } from 'src/components/SentimentAnalysis/SentimentAnalysisHelper'
import { QUERY as FindSettingsSentimentAnalysisQuery } from 'src/components/Settings/SentimentAnalysis/SettingsSentimentAnalysisCell/SettingsSentimentAnalysisCell'
import useAnalytics from 'src/lib/hooks/useAnalytics'

import type { Membership } from '../SettingsSentimentAnalysisCell'

interface MemberManagementHeaderBarProps {
  searchValue: string
  setSearchValue: Dispatch<SetStateAction<string>>
  memberships: Membership[]
  isInvitingUsers: boolean
  selectedRows: Membership[]
  inviteUsers: () => void
  defaultNotificationRecipientMembers: Membership[]
}

const MemberManagementHeaderBar: FC<MemberManagementHeaderBarProps> = ({
  searchValue,
  setSearchValue,
  memberships,
  isInvitingUsers,
  selectedRows,
  inviteUsers,
  defaultNotificationRecipientMembers,
}) => {
  const [inputValue, setInputValue] = useState('')
  const recipients = defaultNotificationRecipientMembers.map((recipient) => ({
    id: recipient.id,
    name: recipient.user.name,
  }))
  const [defaultRecipients, setDefaultRecipients] = useState(
    recipients.length > 0 ? recipients : [],
  )
  const [pendingRecipients, setPendingRecipients] = useState([
    ...defaultRecipients,
  ])

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [saving, setSaving] = useState(false)
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />
  // initialize google analytics
  const { trackEvent } = useAnalytics()
  const filterByOptions = memberships.map((membership) => ({
    id: membership.id,
    name: membership.user.name,
  }))

  const sendAnalyticsEvent = (eventName: string, analyticsLabel: string) => {
    trackEvent('Sentiment Analysis', eventName, {
      filterValue: analyticsLabel,
    })
  }

  const handleInviteClick = () => {
    if (inviteUsers) {
      inviteUsers()
    }
  }

  const handleDialogClose = (action: 'save' | 'cancel') => {
    if (action === 'save') {
      setSaving(true)
      handleUpdateDefaultRecipients(pendingRecipients)
    } else {
      setPendingRecipients([...defaultRecipients])
      setIsDialogOpen(false)
    }
  }

  const handleAutocompleteClose = () => {
    if (
      JSON.stringify(defaultRecipients) !== JSON.stringify(pendingRecipients)
    ) {
      setIsDialogOpen(true)
    }
  }

  const UPDATE_DEFAULT_NOTIFICATION_RECIPIENTS = gql`
    mutation UpdateDefaultNotificationRecipients(
      $recipientMembershipIds: [Int!]!
    ) {
      updateSentimentDefaultNotificationRecipients(
        recipientMembershipIds: $recipientMembershipIds
      )
    }
  `

  const handleUpdateDefaultRecipients = (recipients) => {
    const recipientMembershipIds = recipients.map((recipient) => recipient.id)
    updateSentimentDefaultNotificationRecipients({
      variables: { recipientMembershipIds },
    })
  }

  const [updateSentimentDefaultNotificationRecipients] = useMutation<
    UpdateDefaultNotificationRecipients,
    UpdateDefaultNotificationRecipientsVariables
  >(UPDATE_DEFAULT_NOTIFICATION_RECIPIENTS, {
    onCompleted: () => {
      setDefaultRecipients([...pendingRecipients])
      setSaving(false)
      setIsDialogOpen(false)
      toast.success('Default notification recipients updated successfully.')
      sendAnalyticsEvent('Notification Recipients (default)', 'save')
    },
    onError: (error) => {
      toast.error(error?.message || 'An unexpected error occurred.', {
        duration: 5000,
      })
      setSaving(false)
    },
    refetchQueries: [FindSettingsSentimentAnalysisQuery],
    awaitRefetchQueries: true,
  })

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <TextField
            placeholder="Type keywords to search"
            name="searchText"
            label="Search"
            onChange={(e) => {
              setSearchValue(e.target.value)
            }}
            value={searchValue}
            className="min-w-[400px] grow text-gray-700"
            size="small"
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    {searchValue && (
                      <IconButton
                        className="cursor-pointer hover:bg-transparent"
                        onClick={() => setSearchValue('')}
                      >
                        <XMarkIcon className="h-5 w-5 text-gray-700" />
                      </IconButton>
                    )}
                    <MagnifyingGlassIcon className="h-5 w-5 text-gray-700" />
                  </InputAdornment>
                ),
              },
              inputLabel: { shrink: true },
            }}
          />
          <Button
            variant="outlined"
            color="primary"
            className="py-[6px]"
            disabled={!selectedRows?.length || isInvitingUsers}
            loading={isInvitingUsers}
            onClick={handleInviteClick}
            fullWidth={false}
          >
            Invite Selected
          </Button>
        </div>
        <div className="flex items-center gap-2">
          <Autocomplete
            limitTags={1}
            fullWidth={false}
            className="min-w-[300px]"
            size="small"
            multiple
            disableCloseOnSelect
            disableClearable
            id="sa-default-notification-recipients-select"
            options={filterByOptions}
            getOptionLabel={(option: FilterByOption) => option.name}
            value={pendingRecipients}
            onChange={(
              _event: React.ChangeEvent,
              newValue: FilterByOption[],
            ) => {
              setPendingRecipients(newValue)
              let analyticsLabel: string
              if (newValue.length > 0) {
                analyticsLabel = newValue
                  .map((option) => option.name)
                  .join(', ')
              } else {
                analyticsLabel = 'none selected'
              }
              sendAnalyticsEvent(
                'Notification Recipients (default)',
                analyticsLabel,
              )
            }}
            onClose={handleAutocompleteClose}
            inputValue={inputValue}
            onInputChange={(_event, newInputValue) => {
              setInputValue(newInputValue)
            }}
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Notification Recipients (default)"
                className="capitalize"
                placeholder={
                  pendingRecipients.length === 0 ? 'None Selected' : ''
                }
                slotProps={{
                  input: {
                    ...params.InputProps,
                    startAdornment: pendingRecipients.length > 0 && (
                      <InputAdornment
                        position="start"
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          marginLeft: '8px',
                          color: '#111827',
                        }}
                      >
                        <span>{`${pendingRecipients.length} Selected`}</span>
                      </InputAdornment>
                    ),
                  },
                  inputLabel: { shrink: true },
                }}
              />
            )}
            renderOption={(props, option, { selected }) => {
              return (
                <li {...props} key={option.id} className="capitalize">
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )
            }}
          />
          <Tooltip
            title={'Recipients if no manager is assigned'}
            placement="top"
          >
            <InformationCircleIcon className="h-5 w-5 text-gray-500" />
          </Tooltip>
        </div>
      </div>
      <Dialog
        open={isDialogOpen}
        onClose={() => handleDialogClose('cancel')}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="confirm-dialog-title">Confirm Change</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="confirm-dialog-description"
            className="text-sm"
          >
            Are you sure you want to update the below default notification
            recipients?
          </DialogContentText>
          <div className="pt-4">
            {pendingRecipients.length > 0 ? (
              pendingRecipients.map((recipient) => (
                <div
                  key={recipient.id}
                  className="my-2 flex items-center gap-2 rounded-md border border-gray-200 p-2"
                >
                  <span className="text-gray-700">{recipient.name}</span>
                </div>
              ))
            ) : (
              <span className="text-gray-700">No Recipients Selected</span>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleDialogClose('cancel')}
            color="secondary"
            fullWidth={false}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleDialogClose('save')}
            color="primary"
            fullWidth={false}
            loading={saving}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default MemberManagementHeaderBar
