import React, { FC } from 'react'

import Accordion from 'src/components/Library/Accordion/Accordion'
import SentimentEmailsCell from 'src/components/SentimentAnalysis/SentimentEmailsCell'
import useSentimentStore from 'src/lib/stores/sentimentStore'

import { SentimentAnalysisLandlordAccordionProps } from '../SentimentAnalysisHelper'

import SentimentAnalysisItemHeader from './SentimentAnalysisItemHeader'

const SentimentAnalysisLandlordAccordion: FC<
  SentimentAnalysisLandlordAccordionProps
> = ({
  membership,
  filterDateRange,
  filterESIRating,
  filterByFlag,
  filterByClientStatus,
  debouncedSearchText,
}) => {
  const [selectedBucket] = useSentimentStore((state) => [state.bucketOption])

  return (
    <Accordion
      title={
        <SentimentAnalysisItemHeader
          propertyOwner={{
            id: membership.membershipId,
            name: membership.memberName,
            csiRating: membership.csiRating,
            emailCensored: null,
            emails: null,
            sentimentStatus: null,
          }}
          hideEmailScore={true}
          avatarUrl={membership?.memberAvatarUrl}
          totalEmails={membership.numEmails}
          inboxEmail={membership?.memberEmail}
        />
      }
      removeDetailsPadding={true}
      GATrackerCategory="Sentiment Analysis"
      GATrackerEventName="click admin ESI accordion"
    >
      {membership.membershipId && (
        <SentimentEmailsCell
          filterDateRange={filterDateRange}
          membershipId={membership.membershipId}
          filterESIRating={filterESIRating}
          filterByFlag={filterByFlag}
          filterByClientStatus={filterByClientStatus}
          selectedBucket={selectedBucket}
          debouncedSearchText={debouncedSearchText}
        />
      )}
    </Accordion>
  )
}

export default SentimentAnalysisLandlordAccordion
