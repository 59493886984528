import { useState } from 'react'

import {
  getAllChildrenGroupsFromParentFn,
  type LiteGroup,
} from 'api/src/common/utils'
import { concat, uniqBy } from 'ramda'
import {
  FindMembershipDetailsByClient,
  FindMembershipDetailsByClientVariables,
  type SADateFilterOptions,
} from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import { SelectionType } from 'src/components/Library/SelectWithSort/SelectWithSort'
import {
  FilterByOption,
  FilterByOptionType,
  SASortOptions,
  SentimentAnalysisFlagOptions,
  SentimentAnalysisRatingSelectOptions,
  SentimentStatusSelectOptions,
  type FilterByGroupOption,
} from 'src/components/SentimentAnalysis/SentimentAnalysisHelper'
import SentimentAnalysisFilterBar from 'src/components/SentimentAnalysis/SentimentAnalysisList/SentimentAnalysisFilterBar'
import SettingsSentimentAnalysisScoreGridCell from 'src/components/Settings/SentimentAnalysis/SettingsSentimentAnalysisScoreGridCell'
import useDebounce from 'src/lib/hooks/UseDebounce'

import { MEMBERSHIPS_BY_CLIENT } from '../SettingsSentimentAnalysisQueries'

// Separate component so the query is not called on Tabs other than OVERVIEW
const SettingsSentimentAnalysisFilterBar = () => {
  const [sortByValues, setSortByValues] = useState<SelectionType>({
    value: SASortOptions.esi,
    asc: true,
  })
  const [filterDateRange, setFilterDateRange] =
    useState<SADateFilterOptions>('MONTH')
  const [filterByMember, setFilterByMember] = useState<FilterByOption[]>([])

  const [filterESIRating, setFilterESIRating] =
    useState<SentimentAnalysisRatingSelectOptions>(
      SentimentAnalysisRatingSelectOptions.ALL,
    )

  const [selectedFlagFilter, setSelectedFlagFilter] =
    useState<SentimentAnalysisFlagOptions>(SentimentAnalysisFlagOptions.ALL)

  const [filterByClientStatus, setFilterByClientStatus] =
    useState<SentimentStatusSelectOptions>(SentimentStatusSelectOptions.ALL)

  const [membershipSelectOptions, setMembershipSelectOptions] = useState<
    FilterByOption[]
  >([])

  const [currentSearchText, setCurrentSearchText] = useState('')

  const debouncedSearchText = useDebounce(currentSearchText, 500)

  useQuery<
    FindMembershipDetailsByClient,
    FindMembershipDetailsByClientVariables
  >(MEMBERSHIPS_BY_CLIENT, {
    onCompleted: (data) => {
      if (data?.memberships?.length > 0) {
        const membershipOptions: FilterByOption[] = data.memberships
          .filter(
            (membership) =>
              membership.isActive &&
              membership.landlordsWithEmailsAndSentimentScores.length > 0,
          )
          .map((membership) => {
            const groupIds = membership.membershipGroups.map(
              (group) => group.membershipGroup.id,
            )
            return {
              id: membership.id,
              name: membership.user.name,
              avatarUrl: membership.user.avatarUrl || '/',
              type: FilterByOptionType.MEMBER,
              groupIds: groupIds,
            }
          })

        const membershipGroupIds = membershipOptions.flatMap(
          (membership) => membership.groupIds,
        )

        const filterGroups = data.membershipGroups.map((group) => {
          const result: LiteGroup = {
            id: group.id,
            parentGroupId: group.parentGroupId,
          }
          return result
        })

        const groupOptions = data.membershipGroups
          .filter((group) => !!group.name)
          .map((group) => {
            const childGroups = getAllChildrenGroupsFromParentFn(
              group.id,
              filterGroups,
            )
            const childGroupIds = childGroups.map((childGroup) => childGroup.id)

            const result: FilterByGroupOption = {
              id: group.id,
              name: group.name,
              type: FilterByOptionType.GROUP,
              childGroupIds,
            }
            return result
          })
          .filter((group) => {
            // Filter out groups where no members exist
            const groupMemberIds = [group.id, ...group.childGroupIds]
            return groupMemberIds.some((groupId) =>
              membershipGroupIds.includes(groupId as number),
            )
          })
          .sort((a, b) => a.name.localeCompare(b.name))

        // Combine unique groups and memberships into select options
        const uniqueGroups = uniqBy((group) => group.id, groupOptions)
        const resultOptions = concat(
          uniqueGroups as FilterByOption[],
          membershipOptions,
        )
        setMembershipSelectOptions(resultOptions)
      } else {
        setMembershipSelectOptions([])
      }
    },
  })

  return (
    <>
      <SentimentAnalysisFilterBar
        adminView
        sortByValues={sortByValues}
        setSortByValues={setSortByValues}
        filterDateRange={filterDateRange}
        setFilterDateRange={setFilterDateRange}
        filterByValues={filterByMember}
        setFilterByValues={setFilterByMember}
        filterESIRating={filterESIRating}
        setFilterESIRating={setFilterESIRating}
        filterByOptions={membershipSelectOptions}
        selectedFlagFilter={selectedFlagFilter}
        setSelectedFlagFilter={setSelectedFlagFilter}
        filterByClientStatus={filterByClientStatus}
        setFilterByClientStatus={setFilterByClientStatus}
        currentSearchText={currentSearchText}
        setCurrentSearchText={setCurrentSearchText}
      />
      <SettingsSentimentAnalysisScoreGridCell
        filterDateRange={filterDateRange}
        sortByValues={sortByValues}
        filterByMember={filterByMember}
        filterESIRating={filterESIRating}
        filterByFlag={selectedFlagFilter}
        filterByClientStatus={filterByClientStatus}
        debouncedSearchText={debouncedSearchText}
      />
    </>
  )
}

export default SettingsSentimentAnalysisFilterBar
